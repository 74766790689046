import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { editAppOffers, Offer } from 'api/app';
import { Button } from 'components/general/Button';
import { Select, TextInput } from 'components/form';
import { Modal } from 'components/general/Modal';
import { TooltipType } from 'components/general/Tooltip';
import { CopyCode } from 'components/general/CopyCode';
import { CommonContext } from 'contexts/common';
import { TooltipsContext } from 'contexts/tooltips';
import { numberRe, validateURL } from 'helpers/validators';
import { countries } from 'helpers/countries';
import { createUuid } from 'helpers/common';

interface HandleOfferModalProps {
  onClose: () => void;
  offerUuid?: string;
}

const trackLinkAdditionalParams: Record<string, string> = {
  sub1: '{clickId}',
  sub8: '{appId}',
  sub10: '{deviceId}',
};

export const HandleOfferModal: FC<HandleOfferModalProps> = ({
  onClose,
  offerUuid,
}) => {
  const { uuid } = useParams();

  const { activeApp, campaigns, teams, setActiveApp, fetchApps } =
    useContext(CommonContext);
  const { showTooltip } = useContext(TooltipsContext);

  const defaultValues = useMemo(
    () =>
      activeApp?.offers && offerUuid ? activeApp.offers[offerUuid] : undefined,
    [activeApp?.offers, offerUuid]
  );
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    getValues,
    setValue,
  } = useForm<Offer>({
    mode: 'onChange',
    defaultValues,
  });

  const getMaxOfferPercentage = useCallback(
    (currentTeam: string | undefined) => {
      if (!currentTeam || !activeApp?.offers) return 100;

      const currentGeo = getValues('geo');

      const filteredOffers = Object.fromEntries(
        Object.entries(activeApp.offers).filter(
          ([offerId, { teamName, geo }]) =>
            offerId !== offerUuid &&
            teamName === currentTeam &&
            currentGeo === geo
        )
      );
      const total = Object.values(filteredOffers).reduce(
        (sum, { percentage }) => sum + (percentage || 0),
        0
      );

      return 100 - total;
    },
    [activeApp?.offers, offerUuid, getValues]
  );

  const validatePercentageSplit = useCallback(
    (value: number) => {
      const currentTeam = getValues('teamName');
      const maxOfferPercentage = getMaxOfferPercentage(currentTeam);

      if (value > maxOfferPercentage) {
        return `Percentage should be less than or equal to ${maxOfferPercentage}. The total percentage of all offers for this team can not exceed 100%.`;
      }

      return true;
    },
    [getValues, getMaxOfferPercentage]
  );

  useEffect(() => {
    if (activeApp?.offers && offerUuid) {
      setValue('trackLink', activeApp.offers[offerUuid].trackLink);
    }
  }, [activeApp?.offers, offerUuid, setValue]);

  const campaignOptions = useMemo(
    () =>
      campaigns.map(({ name, id }) => ({
        label: (
          <>
            {id} |<span className="text-sm">{name}</span>
          </>
        ),
        labelText: `${name}, ${id}`,
        value: id,
      })),
    [campaigns]
  );

  const teamOptions = useMemo(
    () =>
      teams.map(({ name }) => ({
        label: name,
        value: name,
      })),
    [teams]
  );

  const countriesOptions = useMemo(
    () =>
      countries.map(({ name, code }) => ({
        label: name,
        value: code,
      })),
    []
  );

  const handleModalClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleAddOffer = useCallback(
    async (values: Offer) => {
      if (!uuid) return;

      try {
        const offerKey = offerUuid || createUuid();
        const filledValues = Object.fromEntries(
          Object.entries(values).filter(([, value]) => value)
        );

        const { data, request } = await editAppOffers(uuid, {
          [offerKey]: { ...filledValues, percentage: +filledValues.percentage },
        });

        if (data) {
          setActiveApp(data);
          fetchApps();

          handleModalClose();

          showTooltip({
            type: TooltipType.SUCCESS,
            content: `Offer ${offerUuid ? 'updated' : 'added'} successfully`,
          });
        } else {
          setError('root', { message: request.responseText });
        }
      } catch (error) {
        showTooltip({ type: TooltipType.ERROR });
      }
      return;
    },
    [
      offerUuid,
      uuid,
      setActiveApp,
      showTooltip,
      handleModalClose,
      setError,
      fetchApps,
    ]
  );

  const updateTrackLink = useCallback(
    (affId: string) => {
      const trackLink = getValues('trackLink');
      if (trackLink) {
        const trackLinkUrl = new URL(trackLink);

        trackLinkUrl.searchParams.set('sub7', `${affId || '{sub7}'}`);

        setValue('trackLink', decodeURI(trackLinkUrl.toString()));
      }
    },
    [getValues, setValue]
  );

  const onCampapaignChange = useCallback(
    (value: number, onChange: (e: any) => void) => {
      const trackLink = campaigns.find(({ id }) => id === value)?.trackLink;

      if (trackLink) {
        const trackLinkUrl = new URL(trackLink);
        Object.keys(trackLinkAdditionalParams).forEach((param) => {
          trackLinkUrl.searchParams.set(
            param,
            trackLinkAdditionalParams[param]
          );
        });

        setValue('trackLink', decodeURI(trackLinkUrl.toString()));
      }

      onChange(value);

      const affId = getValues('affId');

      if (affId) {
        updateTrackLink(affId);
      }
    },
    [campaigns, getValues, setValue, updateTrackLink]
  );

  return (
    <Modal onClose={handleModalClose}>
      <div className="flex flex-col gap-16 justify-center w-[560px]">
        <h2 className="font-bold text-[18px] text-center">
          {offerUuid ? 'Edit' : 'Add'} Offer
        </h2>
        <form
          className="flex flex-col gap-16 items-center w-full"
          onSubmit={handleSubmit(handleAddOffer)}
        >
          <Controller
            name="affId"
            control={control}
            render={({ field }) => (
              <TextInput
                field={field}
                errors={errors}
                label="Affiliate Id"
                onChange={(e) => {
                  field.onChange(e);

                  updateTrackLink(e.target.value);
                }}
              />
            )}
          />

          <Controller
            name="campaignId"
            control={control}
            rules={{
              required: 'Campaign is required',
            }}
            render={({ field: { name, value, onChange } }) => {
              return (
                <Select
                  label="Campaign"
                  name={name}
                  errors={errors}
                  onChange={(value) => onCampapaignChange(value, onChange)}
                  value={value}
                  options={campaignOptions}
                />
              );
            }}
          />

          <Controller
            name="trackLink"
            control={control}
            disabled
            render={({ field }) => (
              <CopyCode code={field.value} label="Track Link" />
            )}
          />

          <Controller
            name="homeLink"
            control={control}
            rules={{
              required: 'Home Link is required',
              validate: validateURL,
            }}
            render={({ field }) => (
              <TextInput field={field} errors={errors} label="Home Link" />
            )}
          />

          <Controller
            name="teamName"
            control={control}
            rules={{
              required: 'Source is required',
            }}
            render={({ field: { name, value, onChange } }) => {
              return (
                <Select
                  label="Source"
                  name={name}
                  errors={errors}
                  onChange={(value) => {
                    const teamName = teams.find(
                      ({ name }) => name === value
                    )?.name;

                    onChange(value);

                    if (teamName) {
                      setValue('teamName', teamName);

                      const maxOfferPercentage =
                        getMaxOfferPercentage(teamName);

                      setValue('percentage', maxOfferPercentage);
                    }
                  }}
                  value={value}
                  options={teamOptions}
                />
              );
            }}
          />

          <Controller
            name="asaCampaignId"
            control={control}
            render={({ field }) => (
              <TextInput
                field={field}
                errors={errors}
                label="ASA Campaign Id"
              />
            )}
          />

          <Controller
            name="geo"
            control={control}
            rules={{
              required: 'Geo is required',
            }}
            render={({ field: { name, value, onChange } }) => {
              return (
                <Select
                  label="Geo"
                  name={name}
                  errors={errors}
                  onChange={onChange}
                  value={value}
                  options={countriesOptions}
                />
              );
            }}
          />

          <Controller
            name="percentage"
            control={control}
            rules={{
              validate: validatePercentageSplit,
              required: 'Percentage is required',
              pattern: {
                value: numberRe,
                message: 'Percentage should be a number',
              },
            }}
            render={({ field }) => (
              <TextInput
                field={field}
                errors={errors}
                type="number"
                label="%"
              />
            )}
          />
          {errors.root && (
            <p className="text-[12px] font-medium text-red-500">
              {errors.root.message}
            </p>
          )}
          <div className="flex gap-8">
            <Button green type="submit">
              Save
            </Button>
            <Button orange type="button" onClick={handleModalClose}>
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};
