import { request } from './base';

export interface Offer {
  campaignId: number;
  trackLink: string;
  homeLink: string;
  geo: string;
  teamName: string;
  percentage: number;
  affId: string;
  asaCampaignId?: string;
}

export interface Offers {
  [key: string]: Offer;
}

export interface App {
  appId: number;
  name: string;
  accessByRoles: number[];
  active: boolean;
  offers?: Offers;
}

export interface Apps {
  [key: string]: App;
}

export const getApps = () =>
  request({
    url: `/apps`,
  });

export const getAppByUuid = (uuid: string) =>
  request({
    url: `/apps/${uuid}`,
  });

export const createApp = (data: App) =>
  request({
    url: `/apps`,
    method: 'POST',
    data,
  });

export const editApp = (uuid: string, data: App) =>
  request({
    url: `/apps/${uuid}`,
    method: 'PUT',
    data,
  });

export const editAppOffers = (uuid: string, data: Partial<App>) =>
  request({
    url: `/apps/${uuid}/offers`,
    method: 'PUT',
    data,
  });

export const deleteAppOffer = (uuid: string, offerUuid: string) =>
  request({
    url: `/apps/${uuid}/${offerUuid}`,
    method: 'DELETE',
  });

export const deleteApp = (uuid: string) =>
  request({
    url: `/apps/${uuid}`,
    method: 'DELETE',
  });

export const toggleAppPermissionByRole = (uuid: string, roleId: number) =>
  request({
    url: `/apps/permissions/${uuid}`,
    method: 'PUT',
    data: {
      roleId,
    },
  });
