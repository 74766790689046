import { FC } from 'react';
import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { AuthContext } from 'contexts/auth';
import { adminMenu, ROUTE } from './helpers';
import { RoleName } from 'api/user';

interface PrivateRouteProps {
  children?: React.ReactNode;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated, pendingUser, user } = useContext(AuthContext);

  if (!isAuthenticated && !pendingUser) {
    return <Navigate to={ROUTE.LOGIN} state={{ from: location }} replace />;
  }

  if (
    user?.role?.name !== RoleName.ADMIN &&
    adminMenu.find(({ link }) => link === location.pathname)
  ) {
    return <Navigate to={ROUTE.DASBOARD} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};
