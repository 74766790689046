import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

interface CreateRequestOptions extends AxiosRequestConfig {
  url: string;
  isPrivate?: boolean;
}

export async function request<T = any>({
  url,
  method = 'GET',
  headers = {},
  data = null,
  isPrivate = true,
}: CreateRequestOptions): Promise<AxiosResponse<T>> {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_PATH,
    timeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT || '20') * 1000,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      ...headers,
    },
  });

  if (isPrivate) {
    const token = localStorage.getItem('token');

    instance.interceptors.request.use((config) => {
      config.headers.Authorization = `Bearer ${token}`;

      return config;
    });
  }

  const requestConfig = {
    method,
    url: isPrivate ? `/private${url}` : url,
    ...(data !== null && { data }),
  };

  try {
    const response = await instance(requestConfig);

    return response;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      const { status, data } = error.response;
      if (data.message === 'Token expired' && status === 401) {
        localStorage.removeItem('token');

        setTimeout(() => {
          window.location.href = '/login';
        }, 2000);
      }

      return error.response;
    } else {
      throw error;
    }
  }
}
