import React, { FC, useCallback, useState } from 'react';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';

interface CopyCodeProps {
  code: any | string;
  label?: string;
}

export const CopyCode: FC<CopyCodeProps> = ({ code, label }) => {
  const [isActive, setIsActive] = useState(false);

  const handleCopyCode = useCallback(() => {
    if (code == null) return;

    navigator.clipboard.writeText(code);
    if (!isActive) {
      setIsActive(true);

      const timeout = setTimeout(() => {
        setIsActive(false);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [code, isActive, setIsActive]);

  return (
    <div className="w-full flex flex-col gap-4">
      {label && (
        <span className="block text-[12px] font-medium text-gray-900">
          {label}
        </span>
      )}
      <div className="min-h-[40px] flex justify-between gap-8 relative rounded-md items-center py-8 px-16 text-gray-900 shadow-sm border-0 placeholder:text-gray-400 ring-1 ring-inset ring-gray-300">
        <span>{code}</span>

        {code && (
          <div className="relative text-gray-500">
            <span
              className={` ${
                isActive ? 'block' : 'hidden'
              } absolute text-xs top-[-16px] right-[-12px]`}
            >
              Copied!
            </span>
            <DocumentDuplicateIcon
              className="w-24 h-24 shrink-0 cursor-pointer text-blue-500"
              onClick={handleCopyCode}
            />
          </div>
        )}
      </div>
    </div>
  );
};
