import { request } from './base';

export interface Team {
  id: number;
  name: string;
}

export const getTeams = () =>
  request({
    url: `/teams`,
  });
