export enum ROUTE {
  // public
  REGISTRATION = '/registration',
  LOGIN = '/login',

  //private
  DASBOARD = '/dashboard',
  APPS = '/dashboard/apps',
  CREATE_APP = '/dashboard/apps/create',
  CONVERSIONS = '/dashboard/conversions',
  USERS = '/dashboard/users',
  PROFILE = '/dashboard/profile',
  PERMISSIONS = '/dashboard/apps-permissions',
}

interface MenuItem {
  title: string | React.ReactNode;
  link: ROUTE;
}

export const generalMenu: MenuItem[] = [
  {
    title: 'Apps',
    link: ROUTE.APPS,
  },
  {
    title: 'Conversions',
    link: ROUTE.CONVERSIONS,
  },
];

export const adminMenu: MenuItem[] = [
  {
    title: 'Users',
    link: ROUTE.USERS,
  },
  {
    title: 'Apps Permissions',
    link: ROUTE.PERMISSIONS,
  },
];
