import { FC, useCallback, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { App as AppI, createApp } from 'api/app';
import { Button } from 'components/general/Button';
import { TextInput } from 'components/form';
import { TooltipsContext } from 'contexts/tooltips';
import { TooltipType } from 'components/general/Tooltip';
import { CommonContext } from 'contexts/common';
import { SwitchToggle } from 'components/general/SwitchToggle';
import { numberRe } from 'helpers/validators';
import { ROUTE } from 'routes/helpers';

export const CreateAppForm: FC = () => {
  const { setActiveApp, fetchApps, apps } = useContext(CommonContext);
  const { showTooltip } = useContext(TooltipsContext);
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AppI>();

  const handleCreateApp = useCallback(
    async ({ appId, ...values }: AppI) => {
      try {
        const {
          data: updatedApp,
          status,
          request,
        } = await createApp({
          ...values,
          appId: +appId,
        });

        if (updatedApp && status === 200) {
          setActiveApp((s) => ({ ...s, ...updatedApp }));
          navigate(`${ROUTE.APPS}/${updatedApp.uuid}`);
          fetchApps();
          return;
        }

        showTooltip({
          type: TooltipType.ERROR,
          content: request.responseText,
        });
      } catch {
        showTooltip({
          type: TooltipType.ERROR,
        });
      }
    },
    [setActiveApp, fetchApps, showTooltip, navigate]
  );

  return (
    <form
      className="w-full flex justify-between gap-24 text-base mb-24"
      onSubmit={handleSubmit(handleCreateApp)}
    >
      <div className="flex flex-col gap-24">
        <div className="flex gap-16 items-center">
          <p className="font-bold min-w-[54px]">Name:</p>
          <Controller
            name="name"
            rules={{
              required: 'Name is required',
            }}
            control={control}
            render={({ field }) => <TextInput field={field} errors={errors} />}
          />
        </div>
        <div className="flex gap-16 items-center">
          <p className="font-bold min-w-[54px]">Id:</p>
          <Controller
            name="appId"
            control={control}
            rules={{
              required: 'App id is required',
              pattern: {
                value: numberRe,
                message: 'App id should be a number',
              },
              validate: (value) =>
                Object.values(apps).find(({ appId }) => appId === +value)
                  ? 'App with such id already exists'
                  : true,
            }}
            render={({ field }) => (
              <TextInput field={field} errors={errors} type="number" />
            )}
          />
        </div>
        <div className="flex gap-16 items-center">
          <p className="font-bold min-w-[54px]">Active:</p>
          <Controller
            name="active"
            control={control}
            defaultValue={false}
            render={({ field: { name, value, onChange } }) => (
              <SwitchToggle id={name} isChecked={value} onChange={onChange} />
            )}
          />
        </div>
      </div>
      <div className="flex gap-8 h-[40px]">
        <Button green className="min-w-[64px]" type="submit">
          Create
        </Button>
        <Button
          className="min-w-[64px]"
          orange
          type="button"
          onClick={() => {
            reset();
            navigate(ROUTE.APPS);
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};
