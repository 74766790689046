import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { createUser, editUser, RegistrationData, RoleName } from 'api/user';
import { PasswordInput, Select, TextInput } from 'components/form';
import { Button } from 'components/general/Button';
import { TooltipType } from 'components/general/Tooltip';
import { AuthContext } from 'contexts/auth';
import { TooltipsContext } from 'contexts/tooltips';
import { userRoles } from 'helpers/user';
import { email } from 'helpers/validators';

interface UserFormProps {
  onClose: () => void;
  userId?: number;
  isEditMode?: boolean;
}

type UserFormData = Omit<RegistrationData, 'confirmPassword'>;

export const UserForm: FC<UserFormProps> = ({
  onClose,
  userId,
  isEditMode,
}) => {
  const { showTooltip } = useContext(TooltipsContext);
  const { fetchUsers, fetchUser, users, user } = useContext(AuthContext);

  const [isResetUserPasswordMode, toggleResetUserPasswordMode] =
    useState(false);

  const activeUser = useMemo(() => {
    if (!userId) return;

    if (userId === user?.id) return user;

    return users.find(({ id }) => id === userId);
  }, [userId, users, user]);

  const defaultValues = useMemo(() => {
    return isEditMode && activeUser
      ? {
          email: activeUser.email,
          name: activeUser.name,
          roleId: activeUser.roleId,
        }
      : { email: '', password: '', name: '', roleId: 2 };
  }, [isEditMode, activeUser]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<UserFormData>({ defaultValues });

  const handleUser = useCallback(
    async (values: UserFormData) => {
      try {
        const { data, request } =
          isEditMode && userId
            ? await editUser(userId, values)
            : await createUser(values);

        if (data) {
          if (userId && userId === user?.id) {
            fetchUser(userId);
          }

          await fetchUsers();

          showTooltip({
            type: TooltipType.SUCCESS,
            content:
              isEditMode && userId
                ? 'User updated successfully'
                : 'User created successfully',
          });

          reset();
          onClose();
        } else {
          setError('root', { message: request.responseText });
        }
      } catch {
        showTooltip({ type: TooltipType.ERROR });
      }
    },
    [
      showTooltip,
      onClose,
      setError,
      reset,
      fetchUsers,
      fetchUser,
      isEditMode,
      userId,
      user?.id,
    ]
  );

  return (
    <div className="flex flex-col gap-16 items-center w-[320px]">
      {isResetUserPasswordMode && userId ? (
        <ResetPasswordForm
          userId={userId}
          onClose={() => toggleResetUserPasswordMode(false)}
        />
      ) : (
        <form
          className="flex flex-col gap-16 items-center w-full"
          onSubmit={handleSubmit(handleUser)}
        >
          <Controller
            name="name"
            control={control}
            rules={{
              required: 'Please enter name',
            }}
            render={({ field }) => (
              <TextInput field={field} label="Name" errors={errors} />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: 'Please enter email',
              validate: email,
            }}
            render={({ field }) => (
              <TextInput field={field} label="Email" errors={errors} />
            )}
          />

          {!isEditMode && (
            <Controller
              name="password"
              control={control}
              rules={
                !isEditMode
                  ? {
                      required: 'Please enter password',
                    }
                  : undefined
              }
              render={({ field }) => (
                <PasswordInput field={field} errors={errors} />
              )}
            />
          )}

          {user?.role?.name === RoleName.ADMIN && (
            <Controller
              name="roleId"
              control={control}
              rules={{
                required: 'Role is required',
              }}
              render={({ field: { value, onChange } }) => {
                return (
                  <Select
                    label="Role"
                    onChange={onChange}
                    value={value}
                    hideSearch
                    options={userRoles}
                  />
                );
              }}
            />
          )}

          {errors.root && (
            <p className="text-[12px] font-medium text-red-500">
              {errors.root.message}
            </p>
          )}
          <div className="flex gap-8">
            <Button green type="submit">
              Save
            </Button>
            <Button type="button" onClick={onClose} orange>
              Cancel
            </Button>
          </div>
        </form>
      )}

      {isEditMode && !isResetUserPasswordMode && (
        <Button
          type="button"
          onClick={() => toggleResetUserPasswordMode((s) => !s)}
          noBg
          className="text-sm min-w-full min-h-[32px]"
        >
          Reset User password
        </Button>
      )}
    </div>
  );
};

interface ResetPasswordFormProps {
  onClose: () => void;
  userId: number;
}

export const ResetPasswordForm: FC<ResetPasswordFormProps> = ({
  userId,
  onClose,
}) => {
  const { showTooltip } = useContext(TooltipsContext);
  const { fetchUsers } = useContext(AuthContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<UserFormData>({ defaultValues: { password: '' } });

  const handleUserPassword = useCallback(
    async (values: UserFormData) => {
      try {
        const { data, request } = await editUser(userId, values);

        if (data) {
          await fetchUsers();

          showTooltip({
            type: TooltipType.SUCCESS,
            content: 'User password updated successfully',
          });

          reset();
          onClose();
        } else {
          setError('root', { message: request.responseText });
        }
      } catch {
        showTooltip({ type: TooltipType.ERROR });
      }
    },
    [showTooltip, onClose, setError, reset, fetchUsers, userId]
  );

  return (
    <form
      className="flex flex-col gap-16 items-center w-full"
      onSubmit={handleSubmit(handleUserPassword)}
    >
      <Controller
        name="password"
        control={control}
        rules={{
          required: 'Please enter password',
        }}
        render={({ field }) => <PasswordInput field={field} errors={errors} />}
      />
      <div className="flex gap-8">
        <Button green type="submit">
          Save
        </Button>
        <Button type="button" onClick={onClose} orange>
          Back
        </Button>
      </div>
    </form>
  );
};
