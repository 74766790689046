import { request } from './base';

export interface Campaign {
  id: number;
  name: string;
  trackLink: string;
}

export const getCampaigns = () =>
  request({
    url: `/campaigns`,
  });
