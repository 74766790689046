import { FC } from 'react';

interface SwitchToggleProps {
  isChecked?: boolean;
  id: string;
  onChange?: (arg: any) => void;
  readOnly?: boolean;
}

export const SwitchToggle: FC<SwitchToggleProps> = ({
  id,
  isChecked,
  onChange,
  readOnly,
}) => {
  return (
    <label
      className={`relative inline-flex items-center cursor-pointer ${
        readOnly ? 'pointer-events-none' : ''
      }`}
    >
      <input
        type="checkbox"
        className="sr-only peer"
        id={id}
        onChange={onChange}
        checked={isChecked}
        readOnly={readOnly}
      />
      <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
    </label>
  );
};
