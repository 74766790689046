import { request } from './base';

export interface Conversion {
  appId: number;
  deviceId: string;
  clickId: string;
  createdAt: string;
}

export interface Conversions {
  [key: string]: Conversion;
}

export const getConversions = () =>
  request({
    url: `/conversions`,
  });
