import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import { App as AppI, editApp } from 'api/app';
import { Button } from 'components/general/Button';
import { TextInput } from 'components/form';
import { OffersTable } from './components/OffersTable';
import { AppActions } from 'components/app/AppActions';
import { TooltipsContext } from 'contexts/tooltips';
import { TooltipType } from 'components/general/Tooltip';
import { CommonContext } from 'contexts/common';
import { SwitchToggle } from 'components/general/SwitchToggle';
import Spinner from 'components/general/Spinner';
import { numberRe } from 'helpers/validators';

export const App: FC = () => {
  const { uuid } = useParams();
  const { activeApp, activeAppPending, fetchActiveApp } =
    useContext(CommonContext);

  const [isEditMode, toggleEditMode] = useState(false);

  useEffect(() => {
    if (!uuid) return;

    fetchActiveApp(uuid);
  }, [fetchActiveApp, uuid]);

  return (
    <div className="w-full px-2 flex flex-col gap-32">
      {activeAppPending ? (
        <Spinner />
      ) : activeApp && uuid ? (
        <>
          <header className="flex gap-8 w-full">
            <div className="w-full flex flex-col gap-8">
              {isEditMode ? (
                <EditApp onClose={() => toggleEditMode(false)} />
              ) : (
                <div className="font-bold flex flex-col gap-24">
                  <p>
                    Name:{' '}
                    <span className="text-[24px]">{activeApp.name || '-'}</span>
                  </p>
                  <p>
                    Id: <span className="text-[24px]">{activeApp.appId}</span>
                  </p>
                  <div className="flex gap-8 font-bold items-center">
                    Active:
                    <SwitchToggle
                      id="active"
                      isChecked={activeApp.active}
                      readOnly
                    />
                  </div>
                </div>
              )}
            </div>
            {!isEditMode && (
              <div className="h-[40px]">
                <AppActions
                  appId={activeApp.appId}
                  uuid={uuid}
                  handleEdit={() => toggleEditMode(true)}
                />
              </div>
            )}
          </header>
          <OffersTable />
        </>
      ) : (
        <p>App does not exist</p>
      )}
    </div>
  );
};

interface EditAppProps {
  onClose: () => void;
}

const EditApp: FC<EditAppProps> = ({ onClose }) => {
  const { uuid } = useParams();

  const { showTooltip } = useContext(TooltipsContext);
  const { activeApp, setActiveApp, fetchApps, apps } =
    useContext(CommonContext);

  const defaultValues = useMemo(() => {
    if (activeApp) {
      const { appId, name, active, accessByRoles } = activeApp;

      return {
        appId,
        name,
        active,
        accessByRoles,
      };
    }
  }, [activeApp]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Omit<AppI, 'offers'>>({
    defaultValues,
  });

  const handleUpdateAppId = useCallback(
    async ({ appId, ...values }: Omit<AppI, 'offers'>) => {
      if (!appId || !uuid) return;

      try {
        const { data: updatedApp } = await editApp(uuid, {
          appId: +appId,
          ...values,
        });

        if (updatedApp) {
          setActiveApp((s) => ({ ...s, ...updatedApp }));
          fetchApps();
        }

        onClose();
      } catch {
        showTooltip({
          type: TooltipType.ERROR,
        });
      }
    },
    [uuid, setActiveApp, onClose, fetchApps, showTooltip]
  );

  return (
    <form
      className="w-full flex justify-between gap-24 text-base mb-24"
      onSubmit={handleSubmit(handleUpdateAppId)}
    >
      <div className="flex flex-col gap-24">
        <div className="flex gap-16 items-center">
          <p className="font-bold min-w-[54px]">Name:</p>
          <Controller
            name="name"
            rules={{
              required: 'Name is required',
            }}
            control={control}
            render={({ field }) => <TextInput field={field} errors={errors} />}
          />
        </div>
        <div className="flex gap-16 items-center">
          <p className="font-bold min-w-[54px]">Id:</p>
          <Controller
            name="appId"
            control={control}
            rules={{
              required: 'App id is required',
              pattern: {
                value: numberRe,
                message: 'App id should be a number',
              },
              validate: (value) =>
                Object.values(apps)
                  .filter(({ appId }) => appId !== activeApp?.appId)
                  .find(({ appId }) => appId === +value)
                  ? 'App with such id already exists'
                  : true,
            }}
            render={({ field }) => (
              <TextInput field={field} errors={errors} type="number" />
            )}
          />
        </div>
        <div className="flex gap-16 items-center">
          <p className="font-bold min-w-[54px]">Active:</p>
          <Controller
            name="active"
            control={control}
            render={({ field: { name, value, onChange } }) => (
              <SwitchToggle id={name} isChecked={value} onChange={onChange} />
            )}
          />
        </div>
      </div>
      <div className="flex gap-8 h-[40px]">
        <Button green className="min-w-[120px] w-full" type="submit">
          Save
        </Button>
        <Button
          className="min-w-[120px] w-full"
          orange
          type="button"
          onClick={() => {
            onClose();
            reset();
          }}
        >
          Cancel
        </Button>
      </div>
    </form>
  );
};
