import { FC, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleIcon } from '@heroicons/react/24/outline';

import { Table } from 'components/general/Table';
import { CommonContext } from 'contexts/common';
import { Button } from 'components/general/Button';
import { AppActions } from 'components/app/AppActions';
import { ROUTE } from 'routes/helpers';

export const AppsList: FC = () => {
  const navigate = useNavigate();

  const { apps } = useContext(CommonContext);

  const tableData = useMemo(
    () =>
      Object.entries(apps).map(
        ([uuid, { name, appId, active, offers }], index) => {
          return {
            index: { value: index + 1 },
            name: { value: name || '-' },
            id: { value: appId },
            active: {
              value: active,
              label: active && (
                <div className="flex justify-center">
                  <CheckCircleIcon className="w-24 h-24 text-green-600" />
                </div>
              ),
            },
            offersAmount: { value: offers ? Object.keys(offers).length : 0 },
            actions: {
              label: <AppActions appId={appId} uuid={uuid} inList />,
            },
          };
        }
      ),
    [apps]
  );

  return (
    <div className="flex flex-col w-full gap-32">
      <header className="w-full pb-16 border-b-2 border-gray-200 flex gap-32">
        <h1 className="w-full text-[24px] font-bold">Apps</h1>
        <Button
          onClick={() => navigate(ROUTE.CREATE_APP)}
          className="text-sm shrink-0"
        >
          Create App
        </Button>
      </header>

      <Table
        withSearch
        data={tableData}
        columns={[
          { header: '№', accessor: 'index', classNames: 'w-[80px]' },
          { header: 'Name', accessor: 'name', classNames: 'w-[20%]' },
          { header: 'Id', accessor: 'id', classNames: 'w-[10%]' },
          {
            accessor: 'active',
            classNames: 'w-[130px]',
            headerClassNames: 'justify-center',
          },
          {
            header: 'Offers Amount',
            accessor: 'offersAmount',
            classNames: 'w-[120px]',
            headerClassNames: 'justify-center',
            cellClassNames: 'text-center',
          },
          {
            accessor: 'actions',
            disableSort: true,
            classNames: 'w-[132px]',
            headerClassNames: 'justify-center',
          },
        ]}
      />
    </div>
  );
};
