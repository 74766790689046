import { FC, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  ArrowRightOnRectangleIcon,
  UserCircleIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline';

import { RoleName } from 'api/user';
import { AuthContext } from 'contexts/auth';
import { Button } from './Button';
import { adminMenu, generalMenu, ROUTE } from 'routes/helpers';

import { ReactComponent as Icon } from 'assets/icon.svg';

export const Sidebar: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { logout, user } = useContext(AuthContext);

  return (
    <nav className="flex flex-col h-full min-h-screen w-[260px] bg-gray-800 p-16 text-white shrink-0">
      <p
        onClick={() => navigate(ROUTE.APPS)}
        className="cursor-pointer flex items-center gap-8 text-[16px] font-bold mb-40 mx-auto tracking-widest font-mono bg-gradient-to-r from-gray-800 to-blue-500 p-4 rounded"
      >
        <Icon className="w-[20px] h-[20px]" /> AppMatrix
      </p>

      <div className="flex gap-8 items-center mb-32">
        <UserCircleIcon className="w-32" />
        <p className="flex flex-col h-auto">
          <span className="flex text-[18px] font-bold">{user?.name}</span>
          <span>{user?.role?.name}</span>
        </p>
      </div>

      <div className="flex flex-col h-full gap-8">
        {generalMenu.map(({ title, link }, idx) => (
          <Link
            key={idx}
            to={link}
            className={`flex gap-8 p-8 hover:bg-gray-500 rounded-lg ${
              pathname === link ? 'bg-gray-500' : ''
            }`}
          >
            <ChevronRightIcon className="w-[16px]" /> {title}
          </Link>
        ))}
        {user?.role?.name === RoleName.ADMIN &&
          adminMenu.map(({ title, link }, idx) => (
            <Link
              key={idx}
              to={link}
              className={`flex gap-8 p-8 hover:bg-gray-500 rounded-lg ${
                pathname === link ? 'bg-gray-500' : ''
              }`}
            >
              <ChevronRightIcon className="w-[16px]" /> {title}
            </Link>
          ))}
        <Link
          to={ROUTE.PROFILE}
          className={`flex gap-8 p-8 hover:bg-gray-500 rounded-lg ${
            pathname === ROUTE.PROFILE ? 'bg-gray-500' : ''
          }`}
        >
          <ChevronRightIcon className="w-[16px]" /> Profile
        </Link>
      </div>

      <div className="mt-auto">
        <Button
          className="w-full"
          gray
          onClick={logout}
          icon={<ArrowRightOnRectangleIcon />}
        >
          Logout
        </Button>
      </div>
    </nav>
  );
};
