import {
  createContext,
  FC,
  useState,
  PropsWithChildren,
  useCallback,
  useEffect,
  useContext,
} from 'react';
import jwt_decode from 'jwt-decode';

import {
  getUserById,
  login as loginRequest,
  registration as registrationRequest,
  LoginData,
  RegistrationData,
  User,
  getUsers,
  hasUsers,
} from 'api/user';
import { TooltipType } from 'components/general/Tooltip';
import { TooltipsContext } from 'contexts/tooltips';

export interface AuthContextData {
  isAuthenticated: boolean;
  hasUsers: boolean;
  user: User | null;
  users: User[];
  pendingUser: boolean;
}

interface AuthContextActions {
  login: (values: LoginData, callbackFunc?: () => void) => void;
  registration: (values: RegistrationData, callbackFunc?: () => void) => void;
  logout: () => void;
  fetchUsers: () => void;
  fetchUser: (userId: number) => void;
}

export const AuthContext = createContext<AuthContextData & AuthContextActions>(
  null as any
);

const initialState = {
  isAuthenticated: false,
  user: null,
  users: [],
  hasUsers: false,
  pendingUser: true,
};

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { showTooltip } = useContext(TooltipsContext);

  const [state, setState] = useState<AuthContextData>(initialState);

  const checkHasUsers = useCallback(async () => {
    try {
      const { data, status } = await hasUsers();

      if (status === 200) {
        setState((s) => ({
          ...s,
          pendingUser: false,
          hasUsers: !!data,
        }));
      }
    } catch {
      showTooltip({ type: TooltipType.ERROR });
    }
  }, [showTooltip]);

  useEffect(() => {
    checkHasUsers();
  }, [checkHasUsers]);

  const getUser = useCallback(
    async (userId: number) => {
      try {
        setState((s) => ({ ...s, pendingUser: true }));

        const { data, status } = await getUserById(userId);

        if (data && status === 200) {
          setState((s) => ({
            ...s,
            isAuthenticated: true,
            user: data,
          }));
        }
      } catch {
        showTooltip({ type: TooltipType.ERROR });
      }

      setState((s) => ({
        ...s,
        pendingUser: false,
      }));
    },
    [showTooltip]
  );

  const fetchUsers = useCallback(async () => {
    try {
      const { data, status } = await getUsers();

      if (data && status === 200) {
        setState((s) => ({
          ...s,
          users: data,
        }));
        return;
      }

      throw new Error(data?.message);
    } catch (error: any) {
      showTooltip({ type: TooltipType.ERROR, content: error?.message });
    }
  }, [showTooltip]);

  const login = useCallback(
    async (values: LoginData, callbackFunc?: () => void) => {
      try {
        setState((s) => ({ ...s, pendingUser: true }));
        const { data } = await loginRequest(values);

        if (data?.id) {
          const { token, ...user } = data;

          localStorage.setItem('token', token);
          setState((s) => ({
            ...s,
            isAuthenticated: true,
            user,
            pendingUser: false,
          }));

          callbackFunc && callbackFunc();
          return;
        } else {
          showTooltip({
            type: TooltipType.ERROR,
            content: data,
          });
        }
      } catch {
        showTooltip({ type: TooltipType.ERROR });
      }

      setState((s) => ({
        ...s,
        pendingUser: false,
      }));
    },
    [showTooltip]
  );

  const registration = useCallback(
    async (values: RegistrationData, callbackFunc?: () => void) => {
      try {
        setState((s) => ({ ...s, pendingUser: true }));
        const { data } = await registrationRequest(values);

        if (data?.id) {
          const { token, ...user } = data;

          localStorage.setItem('token', token);
          setState((s) => ({
            ...s,
            isAuthenticated: true,
            user,
            pendingUser: false,
          }));

          callbackFunc && callbackFunc();
          return;
        }

        throw new Error(data?.message);
      } catch (error: any) {
        showTooltip({ type: TooltipType.ERROR, content: error?.message });
      }
    },
    [showTooltip]
  );

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setState((s) => ({ ...s, isAuthenticated: false, user: null }));

    return;
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token && !state.isAuthenticated) {
      try {
        const decodedToken: { id: number } = jwt_decode(token);

        getUser(decodedToken?.id);
      } catch {
        showTooltip({ type: TooltipType.ERROR });
      }
    }
  }, [state.isAuthenticated, getUser, showTooltip]);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        registration,
        fetchUsers,
        fetchUser: getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
