import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  DocumentPlusIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';

import { Button } from 'components/general/Button';
import { Table } from 'components/general/Table';
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal';

import { CommonContext } from 'contexts/common';
import { TooltipsContext } from 'contexts/tooltips';
import { TooltipType } from 'components/general/Tooltip';
import { HandleOfferModal } from './HandleOfferModal';
import { deleteAppOffer } from 'api/app';

export const OffersTable: FC = () => {
  const { activeApp, campaigns } = useContext(CommonContext);

  const [isCreateModalOpen, toggleCreateModal] = useState(false);

  const offersTableData = useMemo(() => {
    if (!activeApp?.offers) return [];

    return Object.entries(activeApp.offers).map(
      (
        [uuid, { geo, campaignId, asaCampaignId, teamName, percentage, affId }],
        idx
      ) => ({
        index: { value: idx + 1 },
        campaignName: {
          value:
            campaigns.find(({ id }) => id === campaignId)?.name || campaignId,
        },
        teamName: { value: teamName, label: teamName || '-' },
        affId: { value: affId, label: affId || '-' },
        asaCampaignId: { value: asaCampaignId },
        percentage: {
          value: percentage,
          label: `${percentage || 0}%`,
        },
        geo: { value: geo },
        actions: {
          label: <OfferActions offerUuid={uuid} offerIndex={++idx} />,
        },
      })
    );
  }, [activeApp, campaigns]);

  return (
    <>
      <div>
        <div className="w-full mb-16 flex items-center justify-between">
          <h2 className="text-xl font-semibold uppercase">Offers List</h2>
          <Button
            onClick={() => {
              toggleCreateModal(true);
            }}
          >
            Add <DocumentPlusIcon className="w-24 h-24" />
          </Button>
        </div>
        <Table
          columns={[
            { header: '№', accessor: 'index', classNames: 'w-[80px]' },
            {
              header: 'Campaign Name',
              accessor: 'campaignName',
              classNames: 'w-[25%]',
            },
            {
              header: 'Source',
              accessor: 'teamName',
              classNames: 'w-[132px]',
            },
            {
              header: 'ASA Campaign Id',
              accessor: 'asaCampaignId',
              classNames: 'w-[132px]',
            },
            {
              header: 'Affiliate Id',
              accessor: 'affId',
              classNames: 'w-[132px]',
            },
            {
              header: '%',
              accessor: 'percentage',
              headerClassNames: 'justify-center',
              cellClassNames: 'text-center',
              classNames: 'w-[132px]',
            },
            {
              accessor: 'geo',
              headerClassNames: 'justify-center',
              cellClassNames: 'text-center',
              classNames: 'w-[80px]',
            },
            {
              accessor: 'actions',
              disableSort: true,
              classNames: 'w-[132px]',
              headerClassNames: 'justify-center',
            },
          ]}
          data={offersTableData}
        />
      </div>
      {isCreateModalOpen && (
        <HandleOfferModal onClose={() => toggleCreateModal(false)} />
      )}
    </>
  );
};

const OfferActions: FC<{
  offerUuid: string;
  offerIndex: number;
}> = ({ offerUuid, offerIndex }) => {
  const { uuid } = useParams();

  const { setActiveApp } = useContext(CommonContext);
  const { showTooltip } = useContext(TooltipsContext);

  const [isConfirmDeleteModalOpen, toggleConfirmDeleteModal] = useState(false);
  const [isEditModalOpen, toggleEditModal] = useState(false);

  const handleDeleteOffer = useCallback(async () => {
    if (offerUuid && uuid) {
      try {
        const { data } = await deleteAppOffer(uuid, offerUuid);

        if (data) {
          setActiveApp(data);

          toggleConfirmDeleteModal(false);
          showTooltip({
            type: TooltipType.SUCCESS,
            content: 'Offer deleted successfully',
          });
        }
      } catch {
        showTooltip({
          type: TooltipType.ERROR,
        });
      }
    }
  }, [offerUuid, uuid, setActiveApp, showTooltip]);

  return (
    <>
      <div className="flex gap-8 w-full justify-center">
        <Button
          onClick={() => toggleEditModal(true)}
          icon={<PencilSquareIcon />}
        />
        <Button
          red
          onClick={() => toggleConfirmDeleteModal(true)}
          icon={<TrashIcon />}
        />
      </div>
      <ConfirmDeleteModal
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => toggleConfirmDeleteModal(false)}
        onDelete={handleDeleteOffer}
        text={`Are you sure you want to delete this offer #${offerIndex}?`}
      />
      {isEditModalOpen && (
        <HandleOfferModal
          offerUuid={offerUuid}
          onClose={() => toggleEditModal(false)}
        />
      )}
    </>
  );
};
