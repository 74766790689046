import { FC, useCallback, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Navigate, useNavigate } from 'react-router-dom';

import { LoginData } from 'api/user';
import { PasswordInput, TextInput } from 'components/form';
import { Button } from 'components/general/Button';
import { AuthContext } from 'contexts/auth';
import { email } from 'helpers/validators';
import { ROUTE } from 'routes/helpers';

export const Login: FC = () => {
  const navigate = useNavigate();
  const { login, isAuthenticated, hasUsers } = useContext(AuthContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginData>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const loginSubmit = useCallback(
    (values: LoginData) =>
      login(values, () =>
        navigate(ROUTE.APPS, {
          replace: true,
        })
      ),
    [login, navigate]
  );

  if (!isAuthenticated && !hasUsers) {
    return <Navigate to={ROUTE.REGISTRATION} replace />;
  }

  if (isAuthenticated) {
    return <Navigate to={ROUTE.APPS} replace />;
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Login
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit(loginSubmit)}>
          <div className="flex flex-col gap-4">
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Please enter your email',
                validate: email,
              }}
              render={({ field }) => (
                <TextInput field={field} label="Email" errors={errors} />
              )}
            />

            <Controller
              name="password"
              control={control}
              rules={{
                required: 'Please enter password',
              }}
              render={({ field }) => (
                <PasswordInput field={field} errors={errors} />
              )}
            />
          </div>
          <Button className="w-full">Login</Button>
        </form>
      </div>
    </div>
  );
};
