import { createBrowserRouter } from 'react-router-dom';

import { InternalError } from 'components/internal/InternalError';
import { NotFoundBlock } from 'components/internal/NotFound';
import { Layout } from 'Layout';
import { PrivateRoute } from './PrivateRoute';
import { Registration } from 'modules/auth/registration';
import { Login } from 'modules/auth/login';
import { Dashboard } from 'modules/dashboard';
import { AppsList } from 'modules/apps';
import { App } from 'modules/app';
import { ROUTE } from './helpers';
import { ConversionsList } from 'modules/conversions';
import { Users } from 'modules/users';
import { Profile } from 'modules/profile';
import { CreateAppForm } from 'modules/app/components/CreateAppForm';
import { AppsPermissionsList } from 'modules/appsPermissions';

export const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <InternalError />,
    children: [
      { path: ROUTE.REGISTRATION, element: <Registration /> },
      { path: ROUTE.LOGIN, element: <Login /> },
      {
        path: ROUTE.DASBOARD,
        element: (
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        ),
        children: [
          {
            path: `${ROUTE.APPS}/:uuid`,
            element: <App />,
          },
          {
            path: `${ROUTE.CREATE_APP}`,
            element: <CreateAppForm />,
          },
          {
            path: ROUTE.APPS,
            element: <AppsList />,
          },
          {
            path: ROUTE.CONVERSIONS,
            element: <ConversionsList />,
          },
          {
            path: ROUTE.USERS,
            element: <Users />,
          },
          {
            path: ROUTE.PROFILE,
            element: <Profile />,
          },
          {
            path: ROUTE.PERMISSIONS,
            element: <AppsPermissionsList />,
          },
        ],
      },
      { path: '*', element: <NotFoundBlock /> },
    ],
  },
]);
