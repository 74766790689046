import { FC, useContext, useMemo } from 'react';

import { Table } from 'components/general/Table';
import { CommonContext } from 'contexts/common';

export const ConversionsList: FC = () => {
  const { conversions } = useContext(CommonContext);

  const tableData = useMemo(
    () =>
      Object.values(conversions).map(
        ({ appId, clickId, deviceId, createdAt }, index) => {
          const createdDate = new Date(createdAt).toLocaleDateString('en-UK');
          return {
            index: { value: index + 1 },
            appId: { value: appId },
            deviceId: { value: deviceId },
            clickId: { value: clickId },
            createdAt: { value: createdAt, label: <div>{createdDate}</div> },
          };
        }
      ),
    [conversions]
  );

  return (
    <div className="flex flex-col w-full gap-32">
      <header className="w-full pb-16 border-b-2 border-gray-200 flex gap-32">
        <h1 className="w-full text-[24px] font-bold">Conversions</h1>
      </header>

      <Table
        withSearch
        data={tableData}
        columns={[
          { header: '№', accessor: 'index', classNames: 'w-[80px]' },
          { header: 'App Id', accessor: 'appId', classNames: 'w-[25%]' },
          { header: 'Device Id', accessor: 'deviceId' },
          { header: 'Click Id', accessor: 'clickId' },
          { header: 'Created at', accessor: 'createdAt' },
        ]}
      />
    </div>
  );
};
