import { FC, useCallback, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  InformationCircleIcon,
  TrashIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';

import { Button } from 'components/general/Button';
import { CommonContext } from 'contexts/common';
import { ROUTE } from 'routes/helpers';
import { deleteApp } from 'api/app';
import { ConfirmDeleteModal } from 'components/modals/ConfirmDeleteModal';
import { TooltipsContext } from 'contexts/tooltips';
import { TooltipType } from 'components/general/Tooltip';

interface AppActionsProps {
  appId: number;
  uuid: string;
  inList?: boolean;
  handleEdit?: () => void;
}

export const AppActions: FC<AppActionsProps> = ({
  appId,
  uuid,
  inList,
  handleEdit,
}) => {
  const navigate = useNavigate();

  const { showTooltip } = useContext(TooltipsContext);
  const { fetchApps } = useContext(CommonContext);

  const [isConfirmDeleteModalOpen, toggleConfirmDeleteModal] = useState(false);

  const handleDeleteApp = useCallback(async () => {
    if (appId) {
      try {
        const { data } = await deleteApp(uuid);

        if (data) {
          fetchApps();

          toggleConfirmDeleteModal(false);
          navigate(ROUTE.APPS);
          showTooltip({
            type: TooltipType.SUCCESS,
            content: 'App deleted successfully',
          });
        }
      } catch {
        showTooltip({
          type: TooltipType.ERROR,
        });
      }
    }
  }, [uuid, appId, showTooltip, fetchApps, navigate]);

  return (
    <>
      <div className="flex gap-8 w-full justify-center">
        {inList ? (
          <Button
            onClick={() => navigate(`${ROUTE.APPS}/${uuid}`)}
            icon={<InformationCircleIcon />}
          />
        ) : (
          <Button onClick={handleEdit} icon={<PencilSquareIcon />}>
            Edit
          </Button>
        )}
        <Button
          red
          onClick={() => toggleConfirmDeleteModal(true)}
          icon={<TrashIcon />}
        >
          {!inList && 'Delete'}
        </Button>
      </div>
      <ConfirmDeleteModal
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => toggleConfirmDeleteModal(false)}
        onDelete={handleDeleteApp}
        text={`Are you sure you want to delete App with id ${appId}?`}
      />
    </>
  );
};
