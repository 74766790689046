import {
  PropsWithChildren,
  useContext,
  useEffect,
  createContext,
  FC,
  useState,
  useCallback,
} from 'react';

import { App, Apps, getAppByUuid, getApps } from 'api/app';
import { AuthContext } from 'contexts/auth';
import { TooltipsContext } from 'contexts/tooltips';
import { TooltipType } from 'components/general/Tooltip';
import { Conversions, getConversions } from 'api/conversion';
import { Campaign, getCampaigns } from 'api/campaign';
import { getTeams, Team } from 'api/team';

export interface CommonContextData {
  apps: Apps;
  activeApp: App | null;
  activeAppPending: boolean;
  conversions: Conversions;
  campaigns: Campaign[];
  teams: Team[];
}

interface CommonContextActions {
  fetchApps: () => void;
  setApps: React.Dispatch<React.SetStateAction<Apps>>;
  fetchActiveApp: (id: string) => void;
  setActiveApp: React.Dispatch<React.SetStateAction<App | null>>;
  fetchConversions: () => void;
}

export const CommonContext = createContext<
  CommonContextData & CommonContextActions
>(null as any);

export const CommonContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { showTooltip } = useContext(TooltipsContext);
  const { isAuthenticated } = useContext(AuthContext);

  const [apps, setApps] = useState<Apps>({});
  const [conversions, setConversions] = useState<Conversions>({});
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [activeApp, setActiveApp] = useState<App | null>(null);
  const [activeAppPending, setActiveAppPending] = useState<boolean>(false);

  const fetchApps = useCallback(async () => {
    try {
      const { data, status } = await getApps();

      if (data && status === 200) {
        setApps(data);
        return;
      }

      throw new Error(data?.message);
    } catch (error: any) {
      setApps({});
      showTooltip({ type: TooltipType.ERROR, content: error?.message });
    }
  }, [showTooltip]);

  const fetchActiveApp = useCallback(
    async (uuid: string) => {
      try {
        setActiveAppPending(true);

        const { data, status } = await getAppByUuid(uuid);

        if (data?.appId && status === 200) {
          setActiveApp(data);
          return;
        }

        throw new Error(data?.message);
      } catch (error: any) {
        showTooltip({ type: TooltipType.ERROR, content: error?.message });
      } finally {
        setActiveAppPending(false);
      }
    },
    [showTooltip]
  );

  const fetchConversions = useCallback(async () => {
    try {
      const { data, status } = await getConversions();

      if (data && status === 200) {
        setConversions(data);
        return;
      }

      throw new Error(data?.message);
    } catch (error: any) {
      setConversions({});

      showTooltip({ type: TooltipType.ERROR, content: error?.message });
    }
  }, [showTooltip]);

  const fetchCampaigns = useCallback(async () => {
    try {
      const { data, status } = await getCampaigns();

      if (data && status === 200) {
        setCampaigns(data);
        return;
      }

      throw new Error(data?.message);
    } catch (error: any) {
      showTooltip({ type: TooltipType.ERROR, content: error?.message });
    }
  }, [showTooltip]);

  const fetchTeams = useCallback(async () => {
    try {
      const { data, status } = await getTeams();
      if (data && status === 200) {
        setTeams(data);
        return;
      }
      throw new Error(data?.message);
    } catch (error: any) {
      showTooltip({ type: TooltipType.ERROR, content: error?.message });
    }
  }, [showTooltip]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchApps();
      fetchConversions();
      fetchCampaigns();
      fetchTeams();
    }
  }, [
    isAuthenticated,
    fetchApps,
    fetchConversions,
    fetchCampaigns,
    fetchTeams,
  ]);

  return (
    <CommonContext.Provider
      value={{
        apps,
        setApps,
        fetchApps,
        activeApp,
        activeAppPending,
        fetchActiveApp,
        setActiveApp,
        fetchConversions,
        conversions,
        campaigns,
        teams,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};
