import { FC, useCallback, useContext, useMemo } from 'react';

import { Table } from 'components/general/Table';
import { CommonContext } from 'contexts/common';
import { RoleName } from 'api/user';
import { userRoles } from 'helpers/user';
import { SwitchToggle } from 'components/general/SwitchToggle';
import { toggleAppPermissionByRole } from 'api/app';

export const AppsPermissionsList: FC = () => {
  const { apps, setApps } = useContext(CommonContext);

  const togglePermission = useCallback(
    async (uuid: string, roleId: number) => {
      const { data, status } = await toggleAppPermissionByRole(uuid, roleId);

      if (data && status === 200) {
        setApps((prevApps) => ({ ...prevApps, [uuid]: data }));
      }
    },
    [setApps]
  );

  const tableData = useMemo(
    () =>
      Object.entries(apps).map(
        ([uuid, { name, appId, accessByRoles }], index) => {
          const permissions = userRoles.reduce((acc, role) => {
            return {
              ...acc,
              [role.label]: {
                value: role.value,
                isChecked: accessByRoles?.includes(role.value),
              },
            };
          }, {} as { [key: string]: { value: number; isChecked: boolean } });

          return {
            index: { value: index + 1 },
            name: {
              value: name,
            },
            appId: {
              value: appId,
            },

            [RoleName.ASO1]: {
              value: permissions[RoleName.ASO1].value,
              label: (
                <SwitchToggle
                  id={RoleName.ASO1}
                  isChecked={permissions[RoleName.ASO1].isChecked}
                  onChange={() =>
                    togglePermission(uuid, permissions[RoleName.ASO1].value)
                  }
                />
              ),
            },
            [RoleName.ASO2]: {
              value: permissions[RoleName.ASO2].value,
              label: (
                <SwitchToggle
                  id={RoleName.ASO2}
                  isChecked={permissions[RoleName.ASO2].isChecked}
                  onChange={() =>
                    togglePermission(uuid, permissions[RoleName.ASO2].value)
                  }
                />
              ),
            },
          };
        }
      ),
    [apps, togglePermission]
  );

  return (
    <div className="flex flex-col w-full gap-32">
      <header className="w-full pb-16 border-b-2 border-gray-200 flex gap-32">
        <h1 className="w-full text-[24px] font-bold">Apps Permissions</h1>
      </header>

      <Table
        withSearch
        data={tableData}
        columns={[
          { header: '№', accessor: 'index', classNames: 'w-[80px]' },
          { header: 'App Name', accessor: 'name', classNames: 'w-[25%]' },
          { header: 'App Id', accessor: 'appId', classNames: 'w-[25%]' },
          {
            header: RoleName.ASO1,
            accessor: RoleName.ASO1,
            disableSort: true,
            classNames: 'w-[132px]',
            headerClassNames: 'justify-center',
            cellClassNames: 'text-center',
          },
          {
            header: RoleName.ASO2,
            accessor: RoleName.ASO2,
            disableSort: true,
            classNames: 'w-[132px]',
            headerClassNames: 'justify-center',
            cellClassNames: 'text-center',
          },
        ]}
      />
    </div>
  );
};
